import { getFirebaseBackend } from '../../authUtils.js'
import { initFirebaseBackend } from '../../authUtils'

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
    userData: {}
}

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

initFirebaseBackend(firebaseConfig);

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        console.log('Setting currentUser');
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    },

    SET_USER_DATA(state, newValue) {
        state.userData = newValue
        saveState('auth.userData', newValue)
    },
    
    UPDATE_TERM(state, newValue) {
        state.userData.preferredTerm = newValue
        saveState('auth.userData.preferredTerm', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().loginUser(email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
        commit('SET_USER_DATA', null)
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            getFirebaseBackend().logout().then((response) => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    },

    // register the user
    register({ commit, dispatch, getters }, { name, email, password, tier } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(name, email, password, tier).then((response) => {
            const user = response
            console.log('user: ' + user)
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state, dispatch }) {
        console.log('validate');
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        dispatch('loadUserData')
        return user;
    },

    // Load user data from Firestore
    async loadUserData({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const userData = await getFirebaseBackend().getUserData(state.currentUser.uid);
        commit('SET_USER_DATA', userData)
    },

    // Refresh user data from Firestore
    refreshUserData({ dispatch }) {
        dispatch('loadUserData')
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}

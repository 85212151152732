import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";

class FirebaseAuthBackend {
    constructor(firebaseConfig) {
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    sessionStorage.setItem("authUser", JSON.stringify(user));
                    console.log('onAuthStateChanged...');
                    console.log(user.uid);
                } else {
                    sessionStorage.removeItem('authUser');
                }
            });
        }
    }

    /**
     * Registers the user with given details
     */
    registerUser = (name, email, password, tier) => {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((res) => {
                    // start edit
                    console.log(`Adding user... (${email}; ${tier}; ${res.user.uid})`)
                    if (res.user) {
                        firebase.firestore()
                        .collection('users')
                        .doc(res.user.uid)
                        .set({
                            uid: res.user.uid,
                            displayName: name,
                            email: res.user.email,
                            role: 'consumer',
                            signupDate: Math.floor(new Date().getTime() / 1000),
                            status: {
                                isSubscribed: false,
                                expireDate: null,
                                tier: tier,
                            },
                            preferredTerm: 'longterm',
                        })
                        .then(() => {
                            console.log('User added to Firestore')
                        })
                        .catch(error => {
                            console.log(error)
                        })
                    }
                    // end edit
                    firebase.auth().currentUser.updateProfile({
                        displayName: name
                    });
                    console.log('res.user.uid: ' + res.user.uid)
                    resolve(res.user);
                }, (error) => {
                    reject(this._handleError(error));
                });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
                var user = firebase.auth().currentUser;
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + "//" + window.location.host + "/login" }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    setLoggedInUser = (user) => {
        sessionStorage.setItem("authUser", JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem('authUser'))
            return null;
        return JSON.parse(sessionStorage.getItem('authUser'));
    }

    getUserData = (uid) => {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            console.log('Getting user data.')
            let userData = {}
            firebase
                .firestore()
                .collection('users')
                .doc(uid)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        userData.uid = uid
                        // const name = doc.data().displayName
                        userData.role = doc.data().role
                        userData.photoURL = doc.data().photoURL
                        // const email = doc.data().email
                        userData.expireDate = doc.data().status.expireDate
                        userData.tier = doc.data().status.expireDate > Math.trunc(Date.now() / 1000) ? doc.data().status.tier : 'basic'
                        userData.isSubscribed = (Math.trunc(Date.now() / 1000) <= doc.data().status.expireDate)
                        userData.preferredTerm = doc.data().preferredTerm
                        console.log('User data loaded.')
                    } else {
                        console.log('User data not found.')
                    }
                    resolve(userData);
                }, (error) => {
                    reject(this._handleError(error));
                });
        });
    }

    setPreferredTerm = (user, term) => {
        return new Promise((resolve, reject) => {
            firebase
                .firestore()
                .collection('users')
                .doc(user.uid)
                .update({
                    preferredTerm: term
                })
                .then(() => {
                    console.log(`Term updated: ${term}`);
                }, (error) => {
                    reject(this._handleError(error));
                });
        });
    }

    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error) {
        var errorMessage = error.message;
        return errorMessage;
    }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
}

export { initFirebaseBackend, getFirebaseBackend };

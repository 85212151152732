import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/dashboard/default"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/profile/profile"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    // props: true,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logOut");
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/crypto/:pagenum?",
    name: "crypto",
    meta: { authRequired: true },
    component: () => import("./views/crypto/index"),
    props: true,
  },
  {
    path: "/stocks/:pagenum?",
    name: "stocks",
    meta: { authRequired: true },
    component: () => import("./views/stocks/index"),
    props: true,
  },
  {
    path: "/etfs/:pagenum?",
    name: "etfs",
    meta: { authRequired: true },
    component: () => import("./views/etfs/index"),
    props: true,
  },
  {
    path: "/stocks/buy/:pagenum?",
    name: "stocks-buy",
    meta: { authRequired: true },
    component: () => import("./views/stocks/buy/index"),
    props: true,
  },
  {
    path: "/crypto/buy/:pagenum?",
    name: "crypto-buy",
    meta: { authRequired: true },
    component: () => import("./views/crypto/buy/index"),
    props: true,
  },
  {
    path: "/etfs/buy/:pagenum?",
    name: "etfs-buy",
    meta: { authRequired: true },
    component: () => import("./views/etfs/buy/index"),
    props: true,
  },
  {
    path: "/stocks/sell/:pagenum?",
    name: "stocks-sell",
    meta: { authRequired: true },
    component: () => import("./views/stocks/sell/index"),
    props: true,
  },
  {
    path: "/crypto/sell/:pagenum?",
    name: "crypto-sell",
    meta: { authRequired: true },
    component: () => import("./views/crypto/sell/index"),
    props: true,
  },
  {
    path: "/etfs/sell/:pagenum?",
    name: "etfs-sell",
    meta: { authRequired: true },
    component: () => import("./views/etfs/sell/index"),
    props: true,
  },
  {
    path: "/crypto/symbol/:symbol",
    name: "crypto-symbol",
    meta: { authRequired: true },
    component: () => import("./views/crypto/symbol/index"),
  },
  {
    path: "/stocks/symbol/:symbol",
    name: "stocks-symbol",
    meta: { authRequired: true },
    component: () => import("./views/stocks/symbol/index"),
  },
  {
    path: "/etfs/symbol/:symbol",
    name: "etfs-symbol",
    meta: { authRequired: true },
    component: () => import("./views/etfs/symbol/index"),
  },
  {
    path: "/support/faqs",
    name: "FAQs",
    meta: { authRequired: true },
    component: () => import("./views/support/faqs"),
  },
  {
    path: "/order/success",
    name: "Success",
    component: () => import("./views/order/success"),
  },
  {
    path: "/order/cancel",
    name: "Cancel",
    component: () => import("./views/order/cancel"),
  },
];
